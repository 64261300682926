@import "~@aws-amplify/ui/dist/styles.css";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aab7c4 !important;
    font-size: 16px;
}

.ant-input-affix-wrapper {
    border: none !important;
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
    outline: none !important;

}

@media only screen and (max-width: 600px) {
    .label-responsive {
        font-size: 27px !important;
    }

    .title-responsive {
        font-size: 22px !important;
    }
}

/*.ant-menu-title-content, .ant-tabs-tab {*/
/*    color: #0c6030 !important;*/
/*}*/

.site-drawer-render-in-current-wrapper {
    position: relative;
    height: 200px;
    padding: 48px;
    overflow: hidden;
    text-align: center;
    background: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 2px;
}

.icon {
    color: #aab7c4
}

.iconGreen {
    color: darkorange;
}

.pointer:hover {
    cursor: pointer;
}

button:hover {
    cursor: pointer;
}

#reg_btn:hover {
    background: #0c6030 !important;
    color: #e6ebf1 !important;
    cursor: pointer;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.dzu-dropzone {
    overflow: auto !important;
    border: none !important;
}

.dzu-submitButton {
    background: #0c6030 !important;
}

.dzu-inputLabelWithFiles {
    color: #0c6030 !important;
}

.hover-border {
    border: 1px solid #eeeeee !important;
    border-radius: 5px;
    display: block !important;
}

.hover-border:hover {
    border-radius: 5px;
    border: 1px solid #d7d7d7 !important;
}

.footerContainer {
    border-top: 1px solid #eeeeee;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    text-align: center;
    padding: 6px;
    font-size: small;
    color: black;
}

.spacer {
    width: 100%;
    height: 50px;
}
